import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { Suspense, useEffect, useState } from "react";
import Modal from "./components/modal";
import Loader from "./components/loader";
import Sidebar from "./components/modal/sidebar";
import { UserAuthContextProvider } from "./context/userAuth";
import { Toaster } from 'react-hot-toast';

function App() {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => setReady(true), 800)
  }, [])

  return <UserAuthContextProvider>
    <Suspense fallback={<Loader />}>
      {!ready && <Loader />}
      {ready && <RouterProvider router={router} fallbackElement={<Loader />} />}
      <Modal />
      <Sidebar />
      <Toaster position="bottom-center" />
    </Suspense>
  </UserAuthContextProvider>

}

export default App;
