import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

const Error = lazy(() => import('./pages/404'))

const Intro = lazy(() => import('./pages/picker/index'))
const Login = lazy(() => import('./pages/auth/login'))
const Register = lazy(() => import('./pages/auth/register'))
const Forgot = lazy(() => import('./pages/auth/forgot'))

const Women = lazy(() => import('./pages/women'))
const Men = lazy(() => import('./pages/men'))
const Shop = lazy(() => import('./pages/shop'))
const ProductPages = lazy(() => import('./pages/product pages'))

const Admin = lazy(() => import('./pages/admin'))
// const Invitation = lazy(() => import('./pages/anticipation'))
const Account = lazy(() => import('./pages/account'))

const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'))
const ContactUs = lazy(() => import('./pages/contact-us'))

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Intro />,
        errorElement: <Error />,
    },
    {
        path: "/identity/login",
        element: <Login />
    },
    {
        path: "/identity/register",
        element: <Register />
    },
    {
        path: "/identity/forgot",
        element: <Forgot />
    },
    {
        path: "/women",
        element: <Women />
    },
    {
        path: "/men",
        element: <Men />
    },
    {
        path: "/shop",
        element: <Shop />
    },
    {
        path: "/products/:id",
        element: <ProductPages />
    },
    {
        path: "/admin",
        element: <Admin />
    },
    {
        path: "/account",
        element: <Account />
    },
    // {
    //     path: "/",
    //     element: <Invitation />
    // },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />
    },
    { path: "/contact-us", element: <ContactUs />, }
]);