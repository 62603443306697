import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
    updateProfile,
    getIdTokenResult,
    GoogleAuthProvider,
    signInWithPopup,
    getRedirectResult
} from "firebase/auth";
import { auth } from "../firebase/config";
import { useUserStore } from "../store/user";
import { onSnapshot } from "firebase/firestore";
import { getUser, updateUser } from "../services/auth";
// import { fcmToken } from "../services/fcm";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState({});
    const { setDoc } = useUserStore()

    const logIn = async (email, password) => {
        var { user } = await signInWithEmailAndPassword(auth, email, password);
        return user;
    }

    const signUp = async (email, password, name) => {
        var cred = await createUserWithEmailAndPassword(auth, email, password);
        var user = cred.user;
        await updateProfile(user, { displayName: name })
        return user;
    }

    const sendEmailToVerify = () => sendEmailVerification(auth.currentUser)

    const resetPassword = (email) => sendPasswordResetEmail(auth, email)

    const token = () => getIdTokenResult(user)

    function logOut() {
        return signOut(auth);
    }

    async function googleAuth() {
        const provider = new GoogleAuthProvider();
        const { user } = await signInWithPopup(auth, provider)
        await getRedirectResult(auth);
        return user
    }

    const currentUser = () => auth.currentUser;

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
            setUser(currentUser);

            if (currentuser != null) {
                // const token = await fcmToken()
                // updateUser(currentuser.uid, { token })
                //     .catch(e => console.log(e))

                const unSub = onSnapshot(
                    getUser(currentuser.uid),
                    (snap) => setDoc(snap.data()),
                )

                return () => {
                    unSub()
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut, sendEmailToVerify, resetPassword, currentUser, token, googleAuth }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}