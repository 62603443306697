import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'animate.css';
import App from './App';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import reportWebVitals from './reportWebVitals';
import en from './static/i18n/en.json'
import "react-responsive-carousel/lib/styles/carousel.min.css";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
const Root = () => <React.StrictMode>
  <App />
</React.StrictMode>

root.render(<Root />);

// if (process.env.NODE_ENV === "development") {
//   window.onEmulatorsEvaluated(() => {
//     root.render(<Root />);
//   });
// } else {
//   root.render(<Root />);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
