import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from 'firebase/storage'
// import { getMessaging } from 'firebase/messaging'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'

import { getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
    apiKey: "AIzaSyBCYmN6Ni_MQqI40Dun0eIc3N5yhA6BSys",
    authDomain: "immortal-republic.firebaseapp.com",
    projectId: "immortal-republic",
    storageBucket: "immortal-republic.appspot.com",
    messagingSenderId: "280337908070",
    appId: "1:280337908070:web:1a100ca57d9384962008a7",
    measurementId: "G-KCS17K0H3W"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore()

// const messaging = getMessaging(app)
const functions = getFunctions(app)

const storage = getStorage(app)
const analytics = getAnalytics(app)
const remoteConfig = getRemoteConfig(app)

if (process.env.NODE_ENV === "development") {
    window.emulatorsEvaluated = false;
    window.emulatorsEvaluatedListeners = [];
    window.onEmulatorsEvaluated = (listener) => {
        if (window.emulatorsEvaluated) {
            listener();
        } else {
            window.emulatorsEvaluatedListeners.push(listener);
        }
    };

    (async () => {
        // try {
        //     connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
        //     connectFirestoreEmulator(db, '127.0.0.1', 8080);
        //     connectStorageEmulator(storage, "127.0.0.1", 9199);
        //     connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        //     console.info("🎮 Firebase Auth: emulated");
        // } catch (e) {
        //     console.info("🔥 Firebase Auth: not emulated");
        // }

        // Indicate that the emulators have been evaluated
        window.emulatorsEvaluated = true;
        window.emulatorsEvaluatedListeners.forEach(
            (listener) => {
                listener();
            }
        );
    })()
}

export {
    auth,
    db,
    // messaging,
    functions,
    storage,
    analytics,
    remoteConfig
}
