import { db } from '../firebase/config'
import { doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'

export const createUser = (uid, data) => {
    var ref = doc(db, 'users', uid)
    return setDoc(ref, {
        ...data,
        uid,
        joined: serverTimestamp(),
        carts: 0,
        total: 0,
        total_spent: 0,
        total_items_purchased: 0,
        wishlists: []
    })
}

export const getUser = (uid) => {
    var ref = doc(db, 'users', uid)
    return ref
}

export const updateUser = (uid, data) => {
    var ref = doc(db, 'users', uid)
    return updateDoc(ref, data)
}